const formGroupFiles = document.querySelectorAll('.form-group-file');
formGroupFiles.forEach((file) => {
	const fileInput = file.querySelector('input[type=file]');
	const filePreview = file.querySelector('img');
	const filePreviewDefault = filePreview.src;
	$(fileInput).closest('.form-group-file').append('<div class="invalid-feedback-size">Plik jest za duży, maksymalny rozmiar to 3 MB.</div>');
	fileInput.addEventListener('change', (event) => {
		let file = event.target.files[0];
		$(filePreview).prop('src', filePreviewDefault);
		$(fileInput).closest('.form-group-file').find('.invalid-feedback-size').hide();
		if (file) {
			if (file.size > 4 * 1024 * 1024) {
				$(fileInput).closest('.form-group-file').find('.invalid-feedback-size').show();
				fileInput.value = '';
			} else {
				const reader = new FileReader();
				reader.onload = (event) => {
					filePreview.src = event.target.result;
				};
				reader.readAsDataURL(file);
			}
		}
	})
});
