import { setRegisteredData } from "./forms/registered-data";

$('[name="personal_data[billType]"]').on('change', function() {
    $('[name="personal_data[billPos]"]', this.form.elements).closest('.form-group').prop('disabled', $(this).val() != 'Paragon');
}).trigger('change');

$('[name="personal_data[emailRepeat]"]').each(function() {
    let $email = $('[name="personal_data[email]"]', this.form.elements);
    let $emailRepeat = $('[name="personal_data[emailRepeat]"]', this.form.elements);
    $($email).add($emailRepeat).on('input', function() {
        $emailRepeat.toggleValid($email.val() == $emailRepeat.val());
    }).trigger('change');
});

$('#personal_data_billTypeCheckbox').on('change', function() {
    let checked = $(this).prop('checked');
    if (checked) {
        $('#personal_data_billType').attr('value', 'Faktura');
        $('#personal_data_billPos').closest('.form-group').addClass('d-none');
    } else {
        $('#personal_data_billType').attr('value', 'Paragon');
        $('#personal_data_billPos').closest('.form-group').removeClass('d-none');
    }
    $('#personal_data_billPos').prop('disabled', checked);
    $('#personal_data_billPos').prop('required', !checked);
    $('#personal_data_billPos').val('');
});

$('.application-form').on('submit-success', function(event, data) {
    $('#modal-form-application').modal('hide');
    $('#answer').val('');
    setRegisteredData(data, '#modal-application-thanks');

    $('#modal-application-thanks').modal('show');
});

$('.game-run').on('click', function() {
    // page swap
    $('.page').addClass('d-none');
    $('.page-result').removeClass('d-none');
    $('.page-result').css('display', 'block');

    // modal (uncomment if you need modal instead of page swap)
    //$('#modal-form-application').modal('show');
});

$('.result-try-again').on('click', function() {
    $('.page').addClass('d-none');
    $('.page-form').removeClass('d-none');
});

$('[name="personal_data[noPesel]"]').on('change', function() {
    $('[name="personal_data[pesel]"]', this.form.elements).prop('disabled', $(this).prop('checked'));
    let fields = [
        '[name="personal_data[street]"]',
        '[name="personal_data[streetNr]"]',
        '[name="personal_data[flatNr]"]',
        '[name="personal_data[zipCode]"]',
        '[name="personal_data[city]"]',
    ];
    $(fields.join(', '), this.form.elements).closest('.form-group').prop('disabled', !$(this).prop('checked'));
}).trigger('change');

$('.winner-form').on('submit-success', function(event, data) {
    setRegisteredData(data, '#modal-winner-thanks');

    $('#modal-winner-thanks').modal('show');

    $('.form-container').remove();
    $('.after-fill').removeClass('d-none');
});

$('.contact-form').on('submit-success', function(event, data) {
    $('#modal-contact-thanks').modal('show');
});
